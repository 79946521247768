import { useState, useEffect } from "react";
import { Tabs } from "antd";
import "./NavDrawerTabs.scss";
import UnderlinedButton from "../../../../UI/UnderlinedButton/UnderlinedButton";
import { IProduct } from "../../../../../types/IProduct";
import { useProduct } from "../../../../../hooks/useProduct";
import ItemCard from "../../../ItemCard/ItemCard";
import React from "react";

interface DrawerTabsProps {
  options: { key: string; label: string; path: string; id: number }[];
  filterOption: string;
}

export const NavDrawerTabs = (props: DrawerTabsProps) => {
  const { options, filterOption } = props;
  const [activeKey, setActiveKey] = useState<string>(options[0]?.key || "1");
  const [activeId, setActiveId] = useState<number>(options[0]?.id || -1);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>();

  useEffect(() => {
    setActiveKey(options[0]?.key || "1");
    setActiveId(options[0]?.id || 1);
    console.log(options[0]?.label, options[0]?.id);
    // setActiveId(options[0]?.id || -1);
  }, [options]);

  useEffect(() => {
    if (activeId != -1) {
      console.log("activeId: ", activeId);
      const fetchProducts = async () => {
        try {
          if (filterOption === "type") {
            const response = await product.getAll.execute({
              categoryId: [activeId],
            });
            if (response) {
              const extractedProducts = response.map((item) => item.product);
              setProducts(extractedProducts);
              console.log(extractedProducts);
            }
          } else if (filterOption === "collection") {
            const response = await product.getAll.execute({
              collectionId: [activeId],
            });
            if (response) {
              const extractedProducts = response.map((item) => item.product);
              setProducts(extractedProducts);
              console.log(extractedProducts);
            }
          }
        } catch (error) {
          console.error("Błąd podczas pobierania produktów:", error);
        }
      };
      fetchProducts();
    }
  }, [activeId, options]);

  const items = options.map((item) => ({
    key: item.key,
    label: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          // background: "red",
          width: "min-content",
        }}
      >
        {item.label}
      </div>
    ),
    children: (
      <div className="NavDrawerTabsContent">
        <div className="grid-container">
          {products?.slice(0, 4).map((product, index) => {
            return (
              <div key={index} className="grid-item">
                <ItemCard
                  product={product}
                  key={index}
                  content={"product.content"}
                  showButton={false}
                  variant="search"
                />
              </div>
            );
          })}
        </div>
        <UnderlinedButton content={"Zobacz wszystko"} path={item.path} />
      </div>
    ),
  }));

  // const onChange = (key: string) => {
  //   setActiveKey(key);
  // };

  const onChange = (key: string) => {
    setActiveKey(key);

    const selectedOption = options.find((option) => option.key === key);
    if (selectedOption) {
      setActiveId(selectedOption.id);
    }
  };

  return (
    <Tabs
      type="line"
      items={items}
      onChange={onChange}
      centered
      size="small"
      activeKey={activeKey}
      className="NavDrawerTabs"
    />
  );
};
