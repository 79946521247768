import "./ItemCard.scss";
import UnderlinedButton from "../UnderlinedButton/UnderlinedButton";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";
import { IProduct } from "../../../types/IProduct";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WishlistContext from "../../../context/WishlistContext";
import { useStorage } from "../../../hooks/useStorage";

export default function ItemCard(props: {
  product: IProduct;
  content: string;
  showButton: boolean;
  preview?: boolean;
  variant?: "small" | "large" | "catalog" | "search";
}) {
  const { product, content, showButton, variant = "large", preview } = props;
  const [heartChecked, setHeartChecked] = useState(false);
  const navigate = useNavigate();
  const wishlistCtx = useContext(WishlistContext);
  const wishlist = wishlistCtx.items;
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  const handleNavigate = () => {
    if (!preview) {
      navigate(`/produkt/${product.attribute.slug}`);
    }
  };

  const toggleHeart = () => {
    if (heartChecked) {
      wishlistCtx.removeItem(product.id);
    } else {
      wishlistCtx.addItem(product.id);
    }

    setHeartChecked(!heartChecked);
  };

  useEffect(() => {
    setHeartChecked(wishlist.some((item) => item === product.id));
  }, [wishlist]);

  return (
    <div
      className={`itemCard ${variant}`}
      onClick={!showButton ? handleNavigate : undefined}
      style={{ cursor: !showButton ? "pointer" : "default" }}
    >
      <img
        src={`data:image/png;base64,${product.attribute?.pictures[0].directory}`}
        alt="Ładowanie..."
        className="itemCardImage"
      />

      <HeartIcon
        className="icon itemHeartIcon"
        fill={heartChecked ? "white" : "transparent"}
        onClick={(e) => {
          if (preview) {
            e.preventDefault();
            return;
          }
          e.stopPropagation();
          toggleHeart();
        }}
      />
      <p className="productName">{product.attribute?.name}</p>
      {variant !== "search" && (
        <>
          <p className="colorText">
            {product.attribute?.colors
              ? product.attribute.colors.map((color) => color.name).join(" / ")
              : ""}
          </p>

          <p>
            {" "}
            {product?.attribute?.price !== undefined
              ? new Intl.NumberFormat("pl-PL").format(+product.attribute.price)
              : ""}{" "}
            {currency}
          </p>
        </>
      )}

      {showButton && (
        <UnderlinedButton
          content={content}
          path={`/produkt/${product.attribute.slug}`}
          preview={preview}
        />
      )}
    </div>
  );
}
