import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IDescription } from "../types/IDescription";

export const useDescribe = (description: IDescription) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const fetch = () => {
      try {
        document.title = description.title;
        document
          .querySelector('meta[name="description"]')
          ?.setAttribute("content", description.description);
      } catch (error: any) {
        console.log("Error while fetching the description!");
      }
    };

    fetch();
  }, [description]);
};
