import { useState } from "react";
import { useUsers } from "../../../../hooks/useUser";
import { IUser } from "../../../../types/IUser";
import { UserData } from "../../../../types/UserData";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import OtpModal from "../../../UI/OtpModal/OtpModal";
import { Form, Input, Button, notification, Col, Row } from "antd";
import "./UserProfileEdit.scss";

interface UserProfileEditProps {
  user: IUser | null;
  onCancel: () => void;
}

export default function UserProfileEdit({
  user,
  onCancel,
}: UserProfileEditProps) {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  const [form] = Form.useForm();
  const { users } = useUsers();
  const [api, contextHolder] = notification.useNotification();
  const [userRequest, setUserRequest] = useState<IUser>();

  // const notify = (type: NotificationType) => {
  //   api[type]({ message: "The icon suggests current status!" });
  // };

  const onFinish = async (userRequest: IUser) => {
    console.log(userRequest);
    // const newSignInRequest = {
    //   email: signUpRequest?.email || "",
    //   password: signUpRequest?.password || "",
    // };
    // setSignInRequest(newSignInRequest);
    // try {
    //   await auth.signup.execute(signUpRequest);
    //   notify("success");
    // } catch (err) {
    //   setOtpOpen(true);
    //   notify("error");
    // }
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="editForm"
      >
        <Row gutter={60}>
          <Col xs={24} md={8}>
            <Form.Item
              label="Nazwa użytkownika"
              name="username"
              initialValue={user?.username}
              rules={[
                {
                  required: true,
                  message: "Proszę podać nazwę użytkownika",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Email"
              name="email"
              initialValue={user?.email}
              rules={[
                {
                  required: true,
                  message: "Proszę podać email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Numer telefonu"
              name="phone"
              initialValue={user?.contactData?.phone}
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer telefonu",
                },
                {
                  pattern: /^[0-9\s-]+$/,
                  message:
                    "Numer telefonu może zawierać tylko cyfry i myślniki",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={60}>
          <Col xs={24} md={8}>
            <Form.Item
              label="Imię"
              name="name"
              initialValue={user?.contactData?.name}
              rules={[
                {
                  required: true,
                  message: "Proszę podać imię",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Nazwisko"
              name="surname"
              initialValue={user?.contactData?.surname}
              rules={[
                {
                  required: true,
                  message: "Proszę podać nazwisko",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Kraj"
              name="country"
              initialValue={user?.contactData?.country}
              rules={[
                {
                  required: true,
                  message: "Proszę podać kraj",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={60}>
          <Col xs={24} md={4}>
            <Form.Item
              label="Miasto"
              name="city"
              initialValue={user?.contactData?.city}
              rules={[
                {
                  required: true,
                  message: "Proszę podać miasto",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Kod pocztowy"
              name="postalCode"
              initialValue={user?.contactData?.postalCode}
              rules={[
                {
                  required: true,
                  message: "Proszę podać kod pocztowy",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Ulica"
              name="streetName"
              initialValue={user?.contactData?.streetName}
              rules={[
                {
                  required: true,
                  message: "Proszę podać ulicę",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Numer ulicy"
              name="streetNumber"
              initialValue={user?.contactData?.streetNumber}
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer ulicy",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Numer domu"
              name="home"
              initialValue={user?.contactData?.home}
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer domu",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "center", marginTop: "2rem" }}>
          <Button className="submit" htmlType="submit">
            EDYTUJ
          </Button>
        </Form.Item>
      </Form>

      <AdjustableButton text={"ANULUJ"} onClick={onCancel} />
    </>
  );
}
