import { useContext, useEffect, useState } from "react";
import "./ItemCardHover.scss";
import AdjustableButton from "../AdjustableButton/AdjustableButton";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";
import { IProduct } from "../../../types/IProduct";
import WishlistContext from "../../../context/WishlistContext";
import { useStorage } from "../../../hooks/useStorage";

interface ItemCardHoverProps {
  product: IProduct;
  content: string;
  path?: string;
  onClick?: () => void;
}

const ItemCardHover = ({
  product,
  content,
  path,
  onClick,
}: ItemCardHoverProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [heartChecked, setHeartChecked] = useState(false);
  const wishlistCtx = useContext(WishlistContext);
  const wishlist = wishlistCtx.items;
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  const toggleHeart = () => {
    if (heartChecked) {
      wishlistCtx.removeItem(product.id);
    } else {
      wishlistCtx.addItem(product.id);
    }

    setHeartChecked(!heartChecked);
  };

  useEffect(() => {
    setHeartChecked(wishlist.some((item) => item === product.id));
  }, [wishlist]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`itemCard-hover ${isHovered ? "hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={`data:image/png;base64,${product.attribute?.pictures[0].directory}`}
        alt="Ładowanie..."
        className="itemCardImage-hover"
      />
      <HeartIcon
        className="icon itemHeartIcon"
        fill={heartChecked ? "white" : "transparent"}
        onClick={(e) => {
          e.stopPropagation();
          toggleHeart();
        }}
      />
      <p className="productName">{product.attribute?.name}</p>
      <p className="colorText">
        {product.attribute?.colors
          ? product.attribute.colors.map((color) => color.name).join(" / ")
          : ""}
      </p>
      <p>
        {" "}
        {product?.attribute?.price !== undefined
          ? new Intl.NumberFormat("pl-PL").format(+product.attribute.price)
          : ""}{" "}
        {currency}
      </p>
      {isHovered && (
        <div className="adjustable-button-container">
          {onClick ? (
            <AdjustableButton
              text={content}
              backgroundColor="black"
              textColor="white"
              borderColor="white"
              hoverBackgroundColor="white"
              hoverTextColor="black"
              hoverBorderColor="white"
              // href={`/produkt/${product.attribute.slug}`}
              onClick={onClick}
            />
          ) : (
            <AdjustableButton
              text={content}
              backgroundColor="black"
              textColor="white"
              borderColor="white"
              hoverBackgroundColor="white"
              hoverTextColor="black"
              hoverBorderColor="white"
              href={`/produkt/${product.attribute.slug}`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemCardHover;
