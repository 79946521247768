import { createContext, useReducer } from "react";
import { Children } from "../interfaces/children";
import { useStorage } from "../hooks/useStorage";
import { IColor } from "../types/IColor";
import { ISize } from "../types/ISize";

type CartState = {
  items: {
    id: number;
    quantity: number;
    color: IColor;
    size?: ISize | undefined;
  }[];
};

type CartAction =
  | { type: "ADD_ITEM"; id: number; color: IColor; size?: ISize | undefined }
  | { type: "REMOVE_QUANTITY"; id: number }
  | { type: "REMOVE_ITEM"; id: number; color: IColor; size?: ISize | undefined }
  | { type: "CLEAR_CART" };

const CartContext = createContext({
  items: [] as {
    id: number;
    quantity: number;
    color: IColor;
    size?: ISize | undefined;
  }[],
  addItem: (id: number, color: IColor, size?: ISize | undefined) => {},
  removeItem: (id: number, color: IColor, size?: ISize | undefined) => {},
  removeQuantity: (id: number) => {},
  clearCart: () => {},
});

function updateStorage(
  items: {
    id: number;
    quantity: number;
    color: IColor;
    size?: ISize | undefined;
  }[],
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  storage.local.setJSON("cart", items);
}

function cartReducer(
  state: CartState,
  action: CartAction,
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  if (action.type === "ADD_ITEM") {
    const existingCartItemIndex = state.items.findIndex(
      (item) =>
        item.id === action.id &&
        item.color.id === action.color.id &&
        (!action.size || item.size?.id === action.size?.id)
    );

    const updatedItems = [...state.items];

    if (existingCartItemIndex > -1) {
      const existingItem = state.items[existingCartItemIndex];

      const updatedItem = {
        ...existingItem,
        quantity: existingItem.quantity + 1,
      };
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems.push({
        id: action.id,
        quantity: 1,
        color: action.color,
        size: action.size,
      });
    }

    updateStorage(updatedItems, storage);

    return { ...state, items: updatedItems };
  }

  if (action.type === "REMOVE_ITEM") {
    console.log("Przed : ", state.items);
    const updatedItems = state.items.filter(
      (item) =>
        !(
          item.id === action.id &&
          item.color.id === action.color.id &&
          (!action.size || item.size?.id === action.size?.id)
        )
    );

    console.log("PO : ", updatedItems);

    updateStorage(updatedItems, storage);

    return { ...state, items: updatedItems };
  }

  if (action.type === "REMOVE_QUANTITY") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.id
    );

    const updatedItems = [...state.items];

    if (existingCartItemIndex > -1) {
      const existingCartItem = state.items[existingCartItemIndex];

      if (existingCartItem.quantity === 1) {
        updatedItems.splice(existingCartItemIndex, 1);
      } else {
        const updatedItem = {
          ...existingCartItem,
          quantity: existingCartItem.quantity - 1,
        };
        updatedItems[existingCartItemIndex] = updatedItem;
      }
    }

    updateStorage(updatedItems, storage);

    return { ...state, items: updatedItems };
  }

  if (action.type === "CLEAR_CART") {
    updateStorage([], storage);

    return { ...state, items: [] };
  }

  return state;
}

export function CartContextProvider({ children }: Children) {
  const { storage } = useStorage();
  const initialCartState: CartState = {
    items: storage.local.getJSON("cart") || [],
  };

  const wrappedReducer = (state: CartState, action: CartAction) =>
    cartReducer(state, action, storage);

  const [cart, dispatchCartAction] = useReducer(
    wrappedReducer,
    initialCartState
  );

  function addItem(id: number, color: IColor, size?: ISize | undefined) {
    dispatchCartAction({ type: "ADD_ITEM", id, color, size });
  }

  function removeItem(id: number, color: IColor, size?: ISize | undefined) {
    dispatchCartAction({ type: "REMOVE_ITEM", id, color, size });
  }

  function removeQuantity(id: number) {
    dispatchCartAction({ type: "REMOVE_QUANTITY", id });
  }

  function clearCart() {
    dispatchCartAction({ type: "CLEAR_CART" });
  }

  const cartContext = {
    items: cart.items,
    addItem,
    removeItem,
    removeQuantity,
    clearCart,
  };

  return (
    <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
  );
}

export default CartContext;
