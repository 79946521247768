import Page from "../../components/UI/Page/Page";
import CategorySection from "../../components/Pages/Home/CategorySection/CategorySection";
import IdeasForGifts from "../../components/Pages/Home/IdeasForGifts/IdeasForGifts";
import InvisibleCollection from "../../components/Pages/Home/InvisibleCollection/InvisibleCollection";
import NewProductsSection from "../../components/Pages/Home/NewProductsSection/NewProductsSection";
import OfferSection from "../../components/Pages/Home/OfferSection/OfferSection";
import AppointmentSection from "../../components/Pages/Home/AppointmentSection/AppointmentSection";
import DiamondKnowledgeSection from "../../components/Pages/Home/DiamondKnowledgeSection/DiamondKnowledgeSection";
import CustomMadeSection from "../../components/Pages/Home/CustomMadeSection/CustomMadeSection";
import Sizeer from "../../components/Pages/Home/Sizeer/Sizeer";
import RecentlySeen from "../../components/Pages/Home/RecentlySeen/RecentlySeen";
import FollowUs from "../../components/Pages/Home/FollowUs/FollowUs";
import ForHim from "../../components/Pages/Home/ForHim/ForHim";
import OpeningSection from "../../components/Pages/Home/OpeningSection/OpeningSection";
import OurPlacesSectionTabs from "../../components/Pages/Home/OurPlacesSection/OurPlacesSectionTabs";

export default function Home() {
  return (
    <Page>
      <OpeningSection />
      <CategorySection />
      <InvisibleCollection />
      {/* <Bestsellers />  */}
      <OfferSection />
      <ForHim />
      <NewProductsSection />
      <AppointmentSection />
      <CustomMadeSection />
      <DiamondKnowledgeSection />
      <IdeasForGifts />
      {/* <JubilersWorldSection /> */}
      {/* <WeRecommendSection /> */}
      <OurPlacesSectionTabs />
      <RecentlySeen />
      <Sizeer />
      <FollowUs />
    </Page>
  );
}
