import { createContext, useReducer } from "react";
import { Children } from "../interfaces/children";
import { useStorage } from "../hooks/useStorage";

type RecentlySeenState = {
  items: { id: number }[];
};

type RecentlySeenAction = { type: "ADD_ITEM"; id: number };

const RecentlySeenContext = createContext({
  items: [] as { id: number }[],
  addItem: (id: number) => {},
});

function updateStorage(
  items: { id: number }[],
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  storage.local.setJSON("recentlySeen", items);
}

function recentlySeenReducer(
  state: RecentlySeenState,
  action: RecentlySeenAction,
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  if (action.type === "ADD_ITEM") {
    const existingRecentlySeenItemIndex = state.items.findIndex(
      (item) => item.id === action.id
    );

    let updatedItems = [...state.items];

    if (existingRecentlySeenItemIndex > -1) {
      const existingItem = updatedItems.splice(
        existingRecentlySeenItemIndex,
        1
      )[0];
      updatedItems.push(existingItem);
    } else {
      updatedItems.push({ id: action.id });

      if (updatedItems.length > 3) {
        updatedItems.shift();
      }
    }

    updateStorage(updatedItems, storage);
  }

  return state;
}

export function RecentlySeenContextProvider({ children }: Children) {
  const { storage } = useStorage();
  const initialRecentlySeenState: RecentlySeenState = {
    items: storage.local.getJSON("recentlySeen") || [],
  };

  const wrappedReducer = (
    state: RecentlySeenState,
    action: RecentlySeenAction
  ) => recentlySeenReducer(state, action, storage);

  const [recentlySeen, dispatchRecentlySeenAction] = useReducer(
    wrappedReducer,
    initialRecentlySeenState
  );

  function addItem(id: number) {
    dispatchRecentlySeenAction({ type: "ADD_ITEM", id });
  }

  const recentlySeenContext = {
    items: recentlySeen.items,
    addItem,
  };

  console.log("OSTATNIE: ", recentlySeenContext);

  return (
    <RecentlySeenContext.Provider value={recentlySeenContext}>
      {children}
    </RecentlySeenContext.Provider>
  );
}

export default RecentlySeenContext;
