import { useState } from "react";
import { Upload, message, Select, Carousel } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { usePicture } from "../../../../hooks/usePicture";
import { IPicture } from "../../../../types/IPicture";
import { IColor } from "../../../../types/IColor";
import { IStatus } from "../../../../types/IStatus";

const { Dragger } = Upload;

interface PictureUploadProps {
  onSave: (data: IPicture[]) => void;
}

export default function CategoryPictureUpload({ onSave }: PictureUploadProps) {
  const { picture } = usePicture();
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<string>("Brak");
  const [selectedExt, setSelectedExt] = useState<string | null>(null);
  const [newPictures, setNewPictures] = useState<IPicture[]>();
  const [selectedColor, setSelectedColor] = useState<IColor>({
    id: 3,
    name: "Brak",
    status: IStatus.ACCEPTED,
  });

  const handleUpload = async (newFiles: any[]) => {
    if (!selectedExt) {
      message.warning("Proszę wybrać rozszerzenie przed dodaniem zdjęcia.");
      return;
    }

    if (newFiles.length === 0) return;

    const fileWrapper = newFiles[newFiles.length - 1];
    const file = fileWrapper.originFileObj as File;

    console.log("FILE : ", file);
    try {
      const base64String = await picture.encode(file);

      const newPicture: IPicture = {
        id: fileWrapper.uid,
        extension: selectedExt,
        type: selectedType,
        color: selectedColor,
        directory: base64String,
      };

      console.log("Dodaję zdjęcie: ", newPicture);

      const updatedPictures = [...(newPictures || []), newPicture];

      setNewPictures(updatedPictures);

      onSave(updatedPictures.map(({ id, ...rest }) => rest));

      message.success(`${file.name} został pomyślnie przetworzony.`);
    } catch (error) {
      message.error(
        `Wystąpił błąd podczas przetwarzania obrazka ${file.name}.`
      );
      console.log("Error descritpon", error);
    }
  };

  const handleRemove = (file: any) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedPictures = (newPictures || []).filter(
      (_, index) =>
        index !== fileList.findIndex((item) => item.uid === file.uid)
    );
    setNewPictures(updatedPictures);

    // console.log("PO USUNIĘCIU: ", updatedPictures);

    onSave(updatedPictures.map(({ id, ...rest }) => rest));

    message.info(`Plik ${file.name} został usunięty.`);
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file: File) => {
      return false;
    },
    onChange(info: any) {
      const { fileList: newFileList, file } = info;

      setFileList(newFileList);

      if (file.status === "removed") {
        // console.log(`Plik ${file.name} został usunięty.`);
        return;
      }

      handleUpload(newFileList);
    },
    onRemove: handleRemove,
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {(!selectedExt || (newPictures?.length ?? 0) > 1) && (
          <p style={{ lineHeight: "2" }}>
            Wybierz rozszerzenie aby dodać zdjęcia
          </p>
        )}

        <label
          style={{
            display: "block",
            lineHeight: "2",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          Rozszerzenie zdjęcia
        </label>
        <Select
          placeholder="Wybierz rozszerzenie zdjęcia"
          style={{ width: "90%", lineHeight: "2", marginBottom: "10px" }}
          onChange={(value) => setSelectedExt(value)}
          value={selectedExt}
        >
          <Select.Option value="png">png</Select.Option>
          <Select.Option value="jpg">jpg</Select.Option>
        </Select>

        <Dragger {...props} fileList={fileList} disabled={selectedExt == null}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Kliknij lub przeciągnij zdjęcie produktu, aby je przesłać
          </p>
          {/* {loadingCreate && <p>Przesyłanie...</p>}
        {errorCreate && <p style={{ color: "red" }}>Błąd: {errorCreate}</p>} */}
        </Dragger>
      </div>
    </>
  );
}
