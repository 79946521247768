import { createContext, useReducer, useState } from "react";
import { Children } from "../interfaces/children";
import { IProductFilteredRequest } from "../types/IProduct";

type IFilterAction =
  | { type: "SET_CURRENCY"; currency: string }
  | { type: "SET_CATEGORY"; categoryId: number[] }
  | { type: "SET_COLLECTION"; collectionId: number[] }
  | { type: "SET_SUPER_CATEGORY"; superCategoryId: number[] }
  | { type: "SET_TYPE"; typeId: number[] }
  | { type: "SET_COLOR"; colorId: number[] }
  | { type: "SET_PRICE_RANGE"; minPrice: number; maxPrice: number }
  | { type: "SET_SORT"; sortDirection: string }
  | { type: "RESET_FILTERS" };

const initialState: IProductFilteredRequest = {
  currency: "PLN",
};

function filterReducer(
  state: IProductFilteredRequest,
  action: IFilterAction
): IProductFilteredRequest {
  switch (action.type) {
    case "SET_CURRENCY":
      return { ...state, currency: action.currency };
    case "SET_CATEGORY":
      console.log("USTAWIAM KATEGORIE: ", action.categoryId);
      return { ...state, categoryId: action.categoryId };
    case "SET_COLLECTION":
      return { ...state, collectionId: action.collectionId };
    case "SET_SUPER_CATEGORY":
      return { ...state, superCategoryId: action.superCategoryId };
    case "SET_TYPE":
      return { ...state, typeId: action.typeId };
    case "SET_COLOR":
      return { ...state, colorId: action.colorId };
    case "SET_PRICE_RANGE":
      return { ...state, minPrice: action.minPrice, maxPrice: action.maxPrice };
    case "SET_SORT":
      return {
        ...state,
        // sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };
    case "RESET_FILTERS":
      return initialState;
    default:
      return state;
  }
}

const SortContext = createContext({
  filter: initialState,
  setCurrency: (currency: string) => {},
  setCategory: (categoryId: number[]) => {},
  setCollection: (collectionId: number[]) => {},
  setSuperCategory: (superCategoryId: number[]) => {},
  setType: (typeId: number[]) => {},
  setColor: (colorId: number[]) => {},
  setPriceRange: (minPrice: number, maxPrice: number) => {},
  setSort: (sortDirection: string) => {},
  isSorting: true,
  triggerSorting: (state: boolean) => {},
  resetFilters: () => {},
});

export function SortContextProvider({
  children,
  initialFilter = {},
}: {
  children: React.ReactNode;
  initialFilter?: Partial<IProductFilteredRequest>;
}) {
  const [filter, dispatch] = useReducer(filterReducer, {
    ...initialState,
    ...initialFilter,
  });
  const [isSorting, setIsSorting] = useState(true);

  function triggerSorting(state: boolean) {
    setIsSorting(state);
  }
  // export function SortContextProvider({ children }: Children) {
  //   const [filter, dispatch] = useReducer(filterReducer, initialState);

  function setCurrency(currency: string) {
    dispatch({ type: "SET_CURRENCY", currency });
  }

  function setCategory(categoryId: number[]) {
    dispatch({ type: "SET_CATEGORY", categoryId });
  }

  function setCollection(collectionId: number[]) {
    dispatch({ type: "SET_COLLECTION", collectionId });
  }

  function setSuperCategory(superCategoryId: number[]) {
    dispatch({ type: "SET_SUPER_CATEGORY", superCategoryId });
  }

  function setType(typeId: number[]) {
    dispatch({ type: "SET_TYPE", typeId });
  }

  function setColor(colorId: number[]) {
    dispatch({ type: "SET_COLOR", colorId });
  }

  function setPriceRange(minPrice: number, maxPrice: number) {
    dispatch({ type: "SET_PRICE_RANGE", minPrice, maxPrice });
  }

  function setSort(sortDirection: string) {
    dispatch({
      type: "SET_SORT",
      // sortBy,
      sortDirection,
    });
  }

  function resetFilters() {
    dispatch({ type: "RESET_FILTERS" });
  }

  return (
    <SortContext.Provider
      value={{
        filter,
        setCurrency,
        setCategory,
        setCollection,
        setSuperCategory,
        setType,
        setColor,
        setPriceRange,
        setSort,
        resetFilters,
        isSorting,
        triggerSorting,
      }}
    >
      {children}
    </SortContext.Provider>
  );
}

export default SortContext;
