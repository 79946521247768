import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/AboutUs/AboutUs";
import Home from "./pages/Home/Home";
import Sizes from "./pages/Sizes/Sizes";
import Showrooms from "./pages/Showrooms/Showrooms";
import AboutDiamonds from "./pages/AboutDiamonds/AboutDiamonds";
import Gifts from "./pages/Gifts/Gifts";
import CartCheckout from "./pages/CartCheckout/CartCheckout";
import Cart from "./pages/Cart/Cart";
import FAQ from "./pages/FAQ/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy/Privacy";
import Dashboard from "./components/Pages/Admin/Dashboard/Dashboard";
import AdminLayout from "./components/Pages/Admin/AdminLayout";
import Regulations from "./pages/Regulations/Regulations";
import ProductsTable from "./components/Pages/Admin/ProductsTable/ProductsTable";
import Category from "./components/Pages/Admin/Category/Category";
import Users from "./components/Pages/Admin/User/User";
import Collection from "./components/Pages/Admin/Collection2/Collection";
import SuperCategory from "./components/Pages/Admin/SuperCategory/SuperCategory";
import Types from "./components/Pages/Admin/Types/Types";
import Changes from "./components/Pages/Admin/Log/Log";
import Reports from "./components/Pages/Admin/Reports/Reports";
import Product from "./pages/Product/Product";
// import Collections from "./pages/Collections/Collections";
import Collections from "./pages/Collections/Collections";
import Categories from "./pages/Categories/Categories";
import New from "./pages/New/New";
import Delivery from "./pages/Delivery/Delivery";
import Order from "./pages/Order/Order";
import Account from "./pages/Account/Account";
import AllProducts from "./pages/AllProducts/AllProducts";
import Color from "./components/Pages/Admin/Color/Color";
import { ProtectedRoute } from "./components/Pages/Admin/ProtectedRoute/ProtectedRoute";
import { CartContextProvider } from "./context/CartContext";
import { WishlistContextProvider } from "./context/WishlistContext";
import Sitemap from "./components/Pages/Admin/Sitemap/Sitemap";
import MainFoto from "./components/Pages/Admin/MainFoto/MainFoto";
import { RecentlySeenContextProvider } from "./context/RecentlySeenContext";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure/PaymentFailure";

export default function App() {
  return (
    <WishlistContextProvider>
      <CartContextProvider>
        <RecentlySeenContextProvider>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/rozmiary" element={<Sizes />} />
                <Route path="/logowanie" element={<SignIn />} />
                <Route path="/rejestracja" element={<SignUp />} />
                <Route path="/salony" element={<Showrooms />} />
                <Route path="/kontakt" element={<Contact />} />
                <Route path="/o-nas" element={<AboutUs />} />
                <Route path="o-diamentach" element={<AboutDiamonds />} />
                <Route path="/prezenty" element={<Gifts />} />
                <Route path="/zaplac" element={<CartCheckout />} />
                <Route path="/koszyk" element={<Cart />} />
                <Route path="/czesto-zadawane-pytania" element={<FAQ />} />
                <Route
                  path="/polityka-prywatnosci"
                  element={<PrivacyPolicy />}
                />
                <Route path="/regulamin" element={<Regulations />} />
                <Route path="/produkt/:slug" element={<Product />} />
                <Route path="/kolekcje" element={<Collections />} />
                <Route
                  path="/kategorie/:nazwaKategorii"
                  element={<Categories />}
                />
                <Route path="/nowe" element={<New />} />
                <Route path="/dostawa" element={<Delivery />} />
                {/* <Route path="/zamowienie" element={<Order />} /> */}
                <Route path="/konto" element={<Account />} />
                <Route path="/produkty" element={<AllProducts />} />
                <Route path="/platnosc/sukces" element={<PaymentSuccess />} />
                <Route
                  path="/platnosc/odrzucono"
                  element={<PaymentFailure />}
                />

                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/*"
                  element={
                    <ProtectedRoute>
                      <AdminLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="produkty"
                    element={
                      <ProtectedRoute>
                        <ProductsTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="logi"
                    element={
                      <ProtectedRoute role="ADMIN">
                        <Changes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="kategoria"
                    element={
                      <ProtectedRoute>
                        <Category />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="reports"
                    element={
                      <ProtectedRoute>
                        <Reports />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="uzytkownicy"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="kolekcje"
                    element={
                      <ProtectedRoute>
                        <Collection />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="superkategoria"
                    element={
                      <ProtectedRoute>
                        <SuperCategory />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="typy"
                    element={
                      <ProtectedRoute>
                        <Types />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="kolory"
                    element={
                      <ProtectedRoute>
                        <Color />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="sitemap"
                    element={
                      <ProtectedRoute>
                        <Sitemap />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="zdjecie-glowne"
                    element={
                      <ProtectedRoute>
                        <MainFoto />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </Router>
        </RecentlySeenContextProvider>
      </CartContextProvider>
    </WishlistContextProvider>
  );
}
