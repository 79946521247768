import { useParams } from "react-router-dom";
import InfoSection from "../../components/Pages/Home/OpeningSection/InfoSection";
import Page from "../../components/UI/Page/Page";
import MatchingProducts from "./MatchingProducts/MatchingProducts";
import "./Product.scss";
import ProductDescription from "./ProductDescription/ProductDescription";
import ProductDetails from "./ProductDetails/ProductDetails";
import { useContext, useEffect, useState } from "react";
import { useProduct } from "../../hooks/useProduct";
import { IProduct } from "../../types/IProduct";
import { useDescribe } from "../../hooks/useDescribe";
import { IDescription } from "../../types/IDescription";
import RecentlySeenContext from "../../context/RecentlySeenContext";

export default function Product() {
  const recentlySeenCtx = useContext(RecentlySeenContext);
  const { slug } = useParams();
  const { product } = useProduct();
  const [actualProduct, setactualProduct] = useState<IProduct>();
  const [seoDescription, setSeoDescription] = useState<IDescription>({
    title: "Biżuteria MMGold",
    description:
      "Wyjątkowa biżuteria, która podkreśli Twój styl i osobowość. Odkryj naszą kolekcję unikalnych wzorów, stworzonych z dbałością o każdy detal. Idealna na specjalne okazje lub jako wyraz codziennej elegancji. Sprawdź już dziś i znajdź coś dla siebie!",
    keywords: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProduct = async () => {
      // console.log("POBIERAM produkt:", id);
      try {
        if (slug) {
          const response = await product.getBySlug.execute(slug);
          if (response) {
            console.log(response.product);
            setactualProduct(response.product);
            recentlySeenCtx.addItem(response.product.id);

            const seoDescription: IDescription = {
              title: response.product.attribute.seoTitle ?? "Biżuteria MMGold",
              description:
                response.product.attribute.seoDescription ??
                "Wyjątkowa biżuteria, która podkreśli Twój styl i osobowość. Odkryj naszą kolekcję unikalnych wzorów, stworzonych z dbałością o każdy detal. Idealna na specjalne okazje lub jako wyraz codziennej elegancji. Sprawdź już dziś i znajdź coś dla siebie!",
              keywords: response.product.attribute.seoKeywords,
            };
            setSeoDescription(seoDescription);

            // console.log("Pobrano produkt:", id);
          }
        }
      } catch (error) {
        console.error(
          `Błąd podczas pobierania produktu o nazwie ${slug}:`,
          error
        );
      }
    };

    fetchProduct();
  }, [slug]);

  useDescribe(seoDescription);

  return (
    <Page>
      <ProductDetails product={actualProduct} />
      <ProductDescription product={actualProduct} />
      <div className="dlaczego_section">
        <p>DLACZEGO M&M GOLD?</p>
        <InfoSection />
      </div>
      <MatchingProducts />
    </Page>
  );
}
