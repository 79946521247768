import React, { useEffect, useState } from "react";
import "./NewProductsSection.scss";
import ItemCard from "../../../UI/ItemCard/ItemCard";
import Text from "../../../UI/Text/Text";
import { useProduct } from "../../../../hooks/useProduct";
import { IProduct } from "../../../../types/IProduct";

export default function NewProductsSection() {
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute({ isNew: 1 });
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="main-contener">
      <Text
        title="ODKRYJ NASZE NOWOŚCI"
        text="Zanurz się w świecie nowości wśród luksusowej biżuterii."
        align="text-center"
        content=""
        path=""
        showButton={false}
      ></Text>
      <div className="products">
        {products.slice(0, 4).map((product, index) => (
          <ItemCard
            product={product}
            key={index}
            content={"Kup teraz"}
            showButton={true}
            variant="small"
          />
        ))}
      </div>
    </div>
  );
}
