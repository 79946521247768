import React, { useEffect, useState } from "react";
import "./InvisibleCollection.scss";
import Text from "../../../UI/Text/Text";
import ItemCardHover from "../../../UI/ItemCardHover/ItemCardHover";
import { useProduct } from "../../../../hooks/useProduct";
import { IProduct } from "../../../../types/IProduct";

export default function InvisibleCollection() {
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute({ collectionId: [2] });
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="invisible-content">
      <div className="invisible-heading">
        <Text
          title={"INVSIBLE COLLECTION™"}
          text={
            "M&M GOLD INVISIBLE COLLECTION™ to unikatowa biżuteria marki. Nowa jakość na Polskim rynku i najbardziej wyszukany prezent jaki może otrzymać kobieta. Piękna biżuteria powinna być niepowtarzalna. Właśnie taka jest ta tworzona przez naszych artystów."
          }
          showButton={true}
          align={"text-center"}
          content={"Zobacz całą kolekcję"}
          path={"/kolekcje"}
        />
      </div>
      <div className="invisible-cards">
        {products.slice(0, 4).map((product, index) => (
          <ItemCardHover
            key={index}
            product={product}
            content={"ZOBACZ SZCZEGÓŁY"}
          />
        ))}
      </div>
    </div>
  );
}
