import React, { useEffect, useState } from "react";
import "./IdeasForGifts.scss";
import Text from "../../../UI/Text/Text";
import ItemCard from "../../../UI/ItemCard/ItemCard";
import { useProduct } from "../../../../hooks/useProduct";
import { IProduct } from "../../../../types/IProduct";

export default function IdeasForGifts() {
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute();
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="ideas-content">
      <div className="text">
        <Text
          title={"POMYSŁY NA PREZENT"}
          text={
            "Chcesz podarować bliskiej osobie wyjątkowy prezent? Z M&M Gold znajdziesz to, czego szukasz! Nasze propozycje prezentów są różnorodne, oryginalne oraz dopasowane do różnych gustów."
          }
          showButton={false}
          align={"text-left"}
          content={""}
          path={""}
        ></Text>
      </div>
      <div className="items">
        <div className="items-row">
          {products.slice(0, 2).map((product, index) => (
            <ItemCard
              product={product}
              key={index}
              content={"Kup teraz"}
              showButton={true}
              variant="small"
            />
          ))}
        </div>
        <div className="items-row">
          {products.slice(2, 4).map((product, index) => (
            <ItemCard
              product={product}
              key={index}
              content={"Kup teraz"}
              showButton={true}
              variant="small"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
