import { useApi } from "./useApi";
import { IMainFoto } from "../types/IMainFoto";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ICategoriesResponse {
  mainFoto: IMainFoto;
  status: IStatus;
}

export const useMainFoto = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const mainFoto = {
    getAll: useAsync(async (): Promise<ICategoriesResponse[]> => {
      const response = await api.get<ICategoriesResponse[]>("/public/mainFoto");
      return response;
    }),
    get: useAsync(async (id: number): Promise<IMainFoto> => {
      const response = await api.get<IMainFoto>(`/public/mainFoto/${id}`);
      return response;
    }),

    create: useAsync(async (newCategory: IMainFoto): Promise<IMainFoto> => {
      auth.validate();
      const response = await api.post<IMainFoto>(
        "/moderator/mainFoto",
        newCategory
      );
      return response;
    }),

    update: useAsync(
      async (id: number, updatedCategory: IMainFoto): Promise<IMainFoto> => {
        auth.validate();
        const response = await api.put<IMainFoto>(
          `/moderator/mainFoto/${id}`,
          updatedCategory
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/mainFoto/${id}`);
    }),
  };

  return { mainFoto };
};
