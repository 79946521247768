import "./Orders.scss";

interface OrderItem {
  id: string;
  image: string;
  name: string;
  orderNumber: string;
  orderDate: string;
  status: string;
  payment: string;
}

const orderItems: OrderItem[] = [
  {
    id: "1",
    image: "./images/Brylanty.jpeg",
    name: "PIERŚCIONEK ZARĘCZYNOWY Z BRYLANTEM",
    orderNumber: "45678",
    orderDate: "15.12.2024",
    status: "odebrane",
    payment: "opłacone",
  },
  {
    id: "2",
    image: "./images/Category-Naszyjniki.jpeg",
    name: 'PIERŚCIONEK "EMERALD CUT DOUBLE HALO LIMITED"',
    orderNumber: "32186",
    orderDate: "10.05.2021",
    status: "wysłane",
    payment: "opłacone",
  },
];

export default function Orders() {
  return (
    <div className="orders">
      <h2 className="orders__title">MOJE ZAMÓWIENIA</h2>

      {orderItems.length === 0 ? (
        <p className="empty-list">Brak zamówionych produktów.</p>
      ) : (
        <div className="orders__table">
          <div className="orders__header">
            <div className="orders__row">
              <div className="orders__column-header">PRODUKT</div>
              <div className="orders__column-header">NR ZAMÓWIENIA</div>
              <div className="orders__column-header">DATA ZAMÓWIENIA</div>
              <div className="orders__column-header">STATUS</div>
              <div className="orders__column-header">PŁATNOŚĆ</div>
            </div>
          </div>

          <div className="orders__body">
            {orderItems.map((item) => (
              <div key={item.id} className="orders__row">
                <div className="orders__product">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="orders__image"
                  />
                  <span className="orders__product-name">{item.name}</span>
                </div>
                <div className="orders__cell">{item.orderNumber}</div>
                <div className="orders__cell">{item.orderDate}</div>
                <div className="orders__cell">{item.status}</div>
                <div className="orders__cell">{item.payment}</div>
              </div>
            ))}
          </div>
        </div>
        //   <table>
        //   <thead>
        //     <tr>
        //       <th></th>
        //       <th></th>
        //       <th></th>
        //       <th></th>
        //       <th></th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {products.map((item, index) => (
        //       <tr key={index}>
        //         <td style={{ textAlign: "left", width: "70px" }}>
        //           <img
        //             src={`data:image/png;base64,${item.attribute?.pictures[0].directory}`}
        //             alt="Ładowanie..."
        //             className="ProductImage"
        //             style={{ height: "70px" }}
        //           />
        //         </td>
        //         <td>
        //           <p>{item.attribute?.name}</p>
        //         </td>
        //         <td>{item.attribute.colors[0].name}</td>
        //         <td>{item.quantity} X</td>
        //         <td style={{ textAlign: "right", width: "min-content" }}>
        //           {" "}
        //           {item.attribute?.price !== undefined
        //             ? new Intl.NumberFormat("pl-PL").format(
        //                 +item.attribute.price
        //               )
        //             : ""}{" "}
        //           {currency}
        //         </td>
        //       </tr>
        //     ))}
        //   </tbody>
        // </table>
      )}
    </div>
  );
}
