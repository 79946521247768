import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/UI/Page/Page";
import Catalog from "../Categories/Catalog/Catalog";
import Sort from "../Categories/Sort/Sort";
import "./Categories.scss";
import OpeningSection from "./OpeningSection/OpeningSection";
import { useStorage } from "../../hooks/useStorage";
import { useContext, useEffect, useState } from "react";
import { IProductFilteredRequest } from "../../types/IProduct";
import { useCategory } from "../../hooks/useCategory";
import { ICategory } from "../../types/ICategory";
import SortContext, { SortContextProvider } from "../../context/SortContext";
import { useType } from "../../hooks/useType";

export default function Categories() {
  const { nazwaKategorii } = useParams();
  const { storage } = useStorage();
  const { category } = useCategory();
  const { type } = useType();
  const currency = storage.local.get("currency");
  const [actualCategory, setActualCategory] = useState<ICategory>();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await category.getAll.execute();
        if (response) {
          const extractedCategories = response.map((item) => item.category);
          const extractedCategory = extractedCategories.find(
            (item) => item.slug.toLowerCase() === nazwaKategorii?.toLowerCase()
          );

          if (!extractedCategory) {
            navigate("/");
          }
          setActualCategory(extractedCategory);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTypes();
  }, []);

  if (isLoading) {
    return <div>Ładowanie...</div>;
  }

  return actualCategory?.id !== undefined ? (
    <SortContextProvider
      initialFilter={{
        categoryId: [actualCategory.id],
        currency: currency ?? "PLN",
      }}
    >
      <Page>
        <OpeningSection
          picture={actualCategory.picture.directory}
          title={actualCategory?.name ?? ""}
          text={actualCategory?.description ?? ""}
        />
        <Sort displayCategory={false} />
        <Catalog photo={false} title="" />
      </Page>
    </SortContextProvider>
  ) : null;
}
