import { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { ICollection } from "../../../../types/ICollection";
import CollectionPictureUpload from "./CollectionPictureUpload";
import { IPicture } from "../../../../types/IPicture";
type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: ICollection) => void;
  initialValues?: ICollection | null;
  loading: boolean;
};

export default function CollectionModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();
  const [pictureData, setPictureData] = useState<IPicture[]>();

  const handlePictureData = (data: IPicture[]) => {
    setPictureData(data);
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: ICollection) => {
    if (pictureData) {
      const newCollection: ICollection = {
        name: values.name,
        // description: values.description,
        picture: pictureData[0],
      } as ICollection;
      console.log("Values: ", values);
      console.log("NewCollection: ", values);
      onSubmit(newCollection);
    }
  };

  return (
    <Modal
      title={initialValues ? "Edytuj kolekcję" : "Dodaj nową kolekcję"}
      open={visible}
      onCancel={onCancel}
      width={"50%"}
      footer={null}
    >
      <CollectionPictureUpload onSave={handlePictureData} />
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="name"
          label="Nazwa kategorii"
          rules={[{ required: true, message: "Wprowadź nazwę kategorii" }]}
        >
          <Input placeholder="Wprowadź nazwę kategorii" />
        </Form.Item>

        {/* <Form.Item
          name="description"
          label="Opis kategorii"
          rules={[{ required: true, message: "Wprowadź opis kategorii" }]}
        >
          <Input placeholder="Wprowadź opis kategorii" />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj kolekcję" : "Dodaj kolekcję"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
