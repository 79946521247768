import { useEffect, useState } from "react";
import ItemCard from "../../../components/UI/ItemCard/ItemCard";
import Text from "../../../components/UI/Text/Text";
import { useProduct } from "../../../hooks/useProduct";
import "./MatchingProducts.scss";
import { IProduct } from "../../../types/IProduct";

export default function MatchingProducts() {
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute();
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="matchingProductsContianer">
      <Text
        title="PASUJĄCE PRODUKTY"
        text={
          "Jesteś zainteresowany tym produktem? Mamy idealne propozycje," +
          "\n" +
          "które będą stanowić idealne uzupełnienie Twojej stylizacji. "
        }
        align="text-center"
        content=""
        path=""
        showButton={false}
      ></Text>
      <div className="products">
        {products.slice(0, 4).map((product, index) => (
          <ItemCard
            product={product}
            key={index}
            content={"Kup teraz"}
            showButton={true}
            variant="small"
          />
        ))}
      </div>
    </div>
  );
}
