import "./ProductRow.scss";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import { useStorage } from "../../../../hooks/useStorage";
import { IProduct } from "../../../../types/IProduct";

type ProductRowProps = {
  product: IProduct;
  reverse?: boolean;
};

export default function ProductRow({
  product,
  reverse = false,
}: ProductRowProps) {
  const { storage } = useStorage();
  const currency = storage.local.get("currency");
  return (
    <div className={`productRow ${reverse ? "reverse" : ""}`}>
      <img
        src={`data:image/png;base64,${product.attribute?.pictures[0].directory}`}
        alt=""
      />
      <div className="productRowText">
        <div className="text">
          <h2>{product.attribute.name}</h2>
          <p>{product.attribute.description}</p>
          <h3 className="price">
            {product.attribute.price} {currency}
          </h3>
        </div>
        <AdjustableButton
          text={"ZOBACZ SZCZEGÓŁY"}
          href={`/produkt/${product.attribute.slug}`}
        />
      </div>
    </div>
  );
}
