import { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import SuperCategoryModal from "./SuperCategoryModal";
import { ISuperCategory } from "../../../../types/ISuperCategory";
import { useSuperCategory } from "../../../../hooks/useSuperCategory";

function SuperCategory() {
  const { supercategory } = useSuperCategory();
  const [data, setData] = useState<ISuperCategory[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSuperCategory, setCurrentSuperCategory] =
    useState<ISuperCategory | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllSuperCategory,
    loading: loadingSuperCategory,
    // error: errorSuperCategory,
    value: SuperCategories,
  } = supercategory.getAll;

  const {
    execute: createSuperCategory,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = supercategory.create;

  const {
    execute: updateSuperCategory,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = supercategory.update;

  const {
    execute: deleteSuperCategory,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = supercategory.delete;

  useEffect(() => {
    getAllSuperCategory();
  }, []);

  useEffect(() => {
    if (SuperCategories) {
      const extractedData = SuperCategories.map(
        (item: any) => item.superCategory
      );
      setData(extractedData);
    }
  }, [SuperCategories]);

  const handleModalSubmit = async (values: ISuperCategory) => {
    if (currentSuperCategory) {
      try {
        await updateSuperCategory(currentSuperCategory.id, values);
        message.success("Super kategoria zaktualizowana");
        getAllSuperCategory();
      } catch (err) {
        console.error("Błąd przy aktualizacji super kategoii:", err);
        message.error("Błąd przy aktualizacji super kategorii");
      }
    } else {
      try {
        await createSuperCategory(values);
        message.success("Super kategoria dodana");
        getAllSuperCategory();
      } catch (err) {
        console.error("Błąd przy dodawaniu super kategorii:", err);
        message.error("Błąd przy dodawaniu super kategorii");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteSuperCategory(id);
      message.success("Super kategoria usunięta");
      getAllSuperCategory();
    } catch (err) {
      console.error("Błąd przy usuwaniu super kategorii:", err);
      message.error("Błąd przy usuwaniu super kategorii");
    }
  };

  const columns: ColumnsType<ISuperCategory> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: ISuperCategory) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentSuperCategory(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć tę super kategorię?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const filteredData = data?.filter((supercategory) =>
    supercategory.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Super Kategorie</h1>

      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj produktu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentSuperCategory(null);
              setIsModalVisible(true);
            }}
          >
            Dodaj Super Kategorię
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingSuperCategory}
        />

        <SuperCategoryModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentSuperCategory}
          loading={currentSuperCategory ? loadingUpdate : loadingCreate}
        />
      </div>
    </>
  );
}

export default SuperCategory;
