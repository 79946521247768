import "./OpeningSection.scss";
import Text from "../../../components/UI/Text/Text";

interface DrawerProps {
  title: string;
  text: string;
  picture?: string;
}

export default function OpeningSection({ title, text, picture }: DrawerProps) {
  return (
    <div className="categoriesOpening">
      <div className="image-container">
        <img
          src={
            picture
              ? `data:image/png;base64,${picture}`
              : "../images/categories.png"
          }
          alt="Contact Details"
        />
      </div>
      <div className="text-container-opening-section">
        <Text
          title={title}
          text={text}
          showButton={false}
          align="text-left"
          content=""
          path=""
        />
      </div>
    </div>
  );
}
