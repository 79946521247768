import { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { CiUser, CiLock } from "react-icons/ci";
import { useAuth } from "../../hooks/useAuth";
import "./SignInForm.scss";
import { SignInRequest } from "../../types/IAuth";
import OtpModal from "../../components/UI/OtpModal/OtpModal";
type NotificationType = "success" | "info" | "warning" | "error";

export default function SignInForm() {
  const { auth } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [otpOpen, setOtpOpen] = useState(false);
  const [signInRequest, setSignInRequest] = useState<SignInRequest>();

  const notify = (type: NotificationType) => {
    api[type]({ message: "The icon suggests current status!" });
  };

  const onFinish = async (signInRequest: SignInRequest) => {
    setSignInRequest(signInRequest);
    try {
      await auth.signin.execute(signInRequest);
      notify("success");
    } catch (err) {
      setOtpOpen(true);
      notify("error");
    }
  };

  return (
    <>
      {contextHolder}

      <Form
        name="signin"
        onFinish={onFinish}
        style={{
          maxWidth: "400px",
          margin: "0 0",
        }}
        className="SignInForm"
      >
        <h2 style={{ marginBottom: "50px" }}>LOGOWANIE</h2>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Proszę wprowadzić e-mail!" }]}
        >
          <div className="InputRow">
            <CiUser style={{ fontSize: "24px", color: "white" }} />
            <Input
              type="email"
              placeholder="Adres email"
              className="signInInput"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Proszę wprowadzić hasło!" }]}
        >
          <div className="InputRow">
            <CiLock style={{ fontSize: "24px", color: "white" }} />
            <Input.Password placeholder="Hasło" className="signInInput" />
          </div>
        </Form.Item>
        {/* <Form.Item
          name="otp"
          // rules={[{ required: true, message: "Proszę wprowadzić hasło!" }]}
        >
          <div className="InputRow">
            <Input type="text" placeholder="OTP" className="signInInput" />
          </div>
        </Form.Item> */}
        {/* <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <input type="checkbox" style={{ marginRight: "5px" }} />
              Zapamiętaj hasło
            </label>
            <a href="#" style={{ fontSize: "12px" }}>
              Nie pamiętasz hasła?
            </a>
          </div>
        </Form.Item> */}
        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
          <Button className="submit" htmlType="submit">
            Zaloguj się
          </Button>
        </Form.Item>
      </Form>
      <OtpModal
        visible={otpOpen}
        onCancel={() => setOtpOpen(false)}
        signInRequest={signInRequest}
      />
    </>
  );
}
