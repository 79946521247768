import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import {
  IProduct,
  IProductFilteredRequest,
  ISortDirectionEnum,
} from "../types/IProduct";
import { IStatus } from "../types/IStatus";
import { useStorage } from "./useStorage";

export interface IProductResponse {
  product: IProduct;
  status: IStatus;
}

export const useProduct = () => {
  const { api } = useApi();
  const { auth } = useAuth();
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  const product = {
    // getAll: useAsync(async (): Promise<IProductResponse[]> => {
    //   const response = await api.get<IProductResponse[]>(
    //     `/public/product?currency=${currency}`
    //   );
    //   return response;
    // }),122

    getAll: useAsync(
      async (
        params: {
          categoryId?: number[];
          collectionId?: number[];
          superCategoryId?: number[];
          typeId?: number[];
          colorId?: number[];
          minPrice?: number;
          maxPrice?: number;
          isNew?: number;
          sortDirection?: string;
        } = {}
      ): Promise<IProductResponse[]> => {
        const queryParams = new URLSearchParams(
          Object.entries({ currency, ...params })
            .filter(
              ([_, value]) =>
                value !== undefined &&
                value !== null &&
                (!Array.isArray(value) || value.length > 0)
            )
            .reduce((acc, [key, value]) => {
              if (Array.isArray(value)) {
                acc.append(key, value.join(","));
              } else {
                acc.append(key, String(value));
              }
              return acc;
            }, new URLSearchParams())
        );

        const response = await api.get<IProductResponse[]>(
          `/public/product?${queryParams.toString()}`
        );

        return response;
      }
    ),

    get: useAsync(async (id: number): Promise<IProductResponse> => {
      const response = await api.get<IProductResponse>(
        `/public/product/${id}?currency=${currency}`
      );
      return response;
    }),

    getBySlug: useAsync(async (slug: string): Promise<IProductResponse> => {
      const response = await api.get<IProductResponse>(
        `/public/product/slug/${slug}?currency=${currency}`
      );
      return response;
    }),

    search: useAsync(async (phrase: string): Promise<IProductResponse[]> => {
      const response = await api.get<IProductResponse[]>(
        `/public/product/search/${phrase}?currency=${currency}`
      );
      return response;
    }),

    // filter: useAsync(async (filter: IProductFilteredRequest): Promise<IProductResponse[]> => {
    //   const response = await api.get<IProductResponse[]>(
    //     `/public/product/filter/?currency=${currency}`,
    //     filter
    //   );
    //   return response;
    // }),

    create: useAsync(async (newProduct: IProduct): Promise<IProduct> => {
      auth.validate();
      const response = await api.post<IProduct>(
        "/moderator/product",
        newProduct
      );
      return response;
    }),

    update: useAsync(
      async (id: number, updatedProduct: IProduct): Promise<IProduct> => {
        auth.validate();
        const response = await api.put<IProduct>(
          `/moderator/product/${id}`,
          updatedProduct
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/product/${id}`);
    }),
  };

  return { product };
};
