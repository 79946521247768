import { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Select,
} from "antd";
import { IAttribute } from "../../../../types/IAttribute";
import dayjs from "dayjs";
import { IProduct } from "../../../../types/IProduct";
import { useVisibleFields } from "../../../../hooks/useVisableFields";
import { useColor } from "../../../../hooks/useColor";
import { IColor } from "../../../../types/IColor";
import { ISize, ISizeRequest } from "../../../../types/ISize";
const { Option } = Select;

interface AttributeFormProps {
  initialValues: IAttribute | undefined;
  onSave: (data: IAttribute) => void;
  product: IProduct | undefined;
}
const dateFormat = "DD-MM-YYYY";

export default function AttributeForm({
  onSave,
  initialValues,
  product,
}: AttributeFormProps) {
  const [form] = Form.useForm();
  const getVisibleFields = useVisibleFields();
  const [visibleFields, setVisibleFields] = useState({
    metal: false,
    goldTest: false,
    stone: false,
    stoneWeight: false,
    clasp: false,
    referenceNumber: false,
    diameter: false,
    mechanism: false,
    dialColor: false,
    productCondition: false,
    included: false,
    productionDate: false,
    colors: false,
    clarity: false,
    cutType: false,
    isRecommended: true,
    seoTitle: false,
    seoDescription: false,
    seoKeywords: false,
    hotjarId: false,
  });

  const calculateChoice = () => {
    if (product?.superCategory?.id === 9) {
      return getVisibleFields("diamenty");
    } else if (product?.superCategory?.id === 8) {
      return getVisibleFields("zegarki");
    } else if (product?.superCategory?.id === 7) {
      if (product.collection?.id === 32) {
        return getVisibleFields("invisible");
      } else if (product.category?.id === 11) {
        return getVisibleFields("pierscionki_zareczynowe");
      } else if (product.category?.id === 12) {
        return getVisibleFields("obraczki_z_diamentami");
      } else if (product.category?.id === 13) {
        return getVisibleFields("naszyjniki_z_diamentami");
      } else if (product.category?.id === 14) {
        return getVisibleFields("bransoletki_z_diamentami");
      } else if (product.category?.id === 15) {
        return getVisibleFields("kolczyki_z_diamentami");
      } else if (product.category?.id === 16) {
        return getVisibleFields("bizuteria_dawna");
      } else if (product.category?.id === 17) {
        return getVisibleFields("bizuteria_art_deco");
      } else if (product.category?.id === 18) {
        return getVisibleFields("bizuteria_platynowa");
      } else if (product.category?.id === 19) {
        return getVisibleFields("high_jewels");
      }
    }
    return getVisibleFields("");
  };
  const [colorsTab, setColorsTab] = useState<IColor[]>([]);
  const [selectedColors, setSelectedColors] = useState<IColor[]>([]);
  const { color } = useColor();
  const [inputValue, setInputValue] = useState("");
  const [unitValue, setUnitValue] = useState("cm");
  const [sizesTab, setSizesTab] = useState<ISizeRequest[]>([]);
  const [selectedSizes, setSelectedSizes] = useState<ISizeRequest[]>([]);

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await color.getAll.execute();
        if (response) {
          const extractedColors = response.map((item) => item.color);
          setColorsTab(extractedColors);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania kolorów:", error);
      }
    };

    fetchColors();
  }, []);

  const handleColorChange = (checkedValues: string[]) => {
    const selected = checkedValues.map(
      (colorName) => colorsTab.find((color) => color.name === colorName)!
    );
    setSelectedColors(selected);
  };

  const handleSizeChange = (checkedValues: string[]) => {
    const selected = checkedValues.map(
      (sizeValue) => sizesTab.find((size) => size.value === sizeValue)!
    );
    setSelectedSizes(selected);
  };

  useEffect(() => {
    const choice = calculateChoice();
    setVisibleFields(choice);
    if (initialValues && product) {
      console.log("PRODUKT: ", product);
      form.setFieldsValue(product.attribute);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values: IAttribute) => {
    // console.log("Otrzymane wartości:", values);
    const formattedValues = {
      ...values,
      isNew: values.isNew ? 1 : 0,
      colors: selectedColors,
      sizes: selectedSizes as ISize[],
      productionDate: values.productionDate
        ? dayjs(values.productionDate).format(dateFormat)
        : undefined,
      isRecommended: values.isRecommended ? 1 : 0,
    } as IAttribute;
    console.log("Formatowane wartości:", formattedValues);
    onSave(formattedValues);
  };

  const handleAddOption = () => {
    if (
      inputValue.trim() &&
      !sizesTab.some((opt) => opt.value === inputValue.trim())
    ) {
      setSizesTab([...sizesTab, { value: inputValue.trim(), unit: unitValue }]);
      setInputValue("");
    }
  };

  return (
    <Form
      form={form}
      name="attributeForm"
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues ? initialValues : { isRecommended: false }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Nazwa"
            name="name"
            rules={[{ required: true, message: "Proszę podać nazwę" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Cena"
            name="price"
            rules={[{ required: true, message: "Proszę podać cenę" }]}
          >
            <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Opis"
        name="description"
        rules={[{ required: true, message: "Proszę podać opis" }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Row gutter={16}>
        {visibleFields.metal && (
          <Col span={12}>
            <Form.Item label="Metal" name="metal">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.goldTest && (
          <Col span={12}>
            <Form.Item label="Próba złota" name="goldTest">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.stone && (
          <Col span={12}>
            <Form.Item label="Kamień" name="stone">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.stoneWeight && (
          <Col span={12}>
            <Form.Item label="Waga kamienia (ct)" name="stoneWeight">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.clasp && (
          <Col span={12}>
            <Form.Item label="Zapięcie" name="clasp">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.referenceNumber && (
          <Col span={12}>
            <Form.Item label="Numer referencyjny" name="referenceNumber">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.diameter && (
          <Col span={12}>
            <Form.Item label="Średnica (mm)" name="diameter">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}

        {visibleFields.mechanism && (
          <Col span={12}>
            <Form.Item label="Mechanizm" name="mechanism">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.dialColor && (
          <Col span={12}>
            <Form.Item label="Kolor tarczy" name="dialColor">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.productCondition && (
          <Col span={12}>
            <Form.Item label="Stan" name="productCondition">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.included && (
          <Col span={12}>
            <Form.Item label="W komplecie" name="included">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.productionDate && (
          <Col span={12}>
            <Form.Item
              label="Data produkcji"
              name="productionDate"
              getValueProps={(value) => ({
                value: value ? dayjs(value, dateFormat) : null,
              })}
            >
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {/* {visibleFields.color && (
          <Col span={12}>
            <Form.Item label="Kolor" name="color">
              <Input />
            </Form.Item>
          </Col>
        )} */}

        {visibleFields.colors && (
          <Col span={12}>
            <Form.Item
              label="Kolor"
              name="colors"
              rules={[{ required: true, message: "Proszę wybrać kolor" }]}
            >
              <Checkbox.Group
                onChange={(checkedValues) =>
                  handleColorChange(checkedValues as string[])
                }
              >
                {colorsTab.map((color) => (
                  <Checkbox key={color.id} value={color.name}>
                    {color.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        )}

        {visibleFields.clarity && (
          <Col span={12}>
            <Form.Item label="Czystość" name="clarity">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      {visibleFields.cutType && (
        <Form.Item label="Rodzaj szlifu" name="cutType">
          <Input />
        </Form.Item>
      )}

      {visibleFields.seoTitle && (
        <Form.Item label="Tag <title>" name="seoTitle">
          <Input />
        </Form.Item>
      )}

      {visibleFields.seoDescription && (
        <Form.Item label="Tag <meta> - description" name="seoDescription">
          <Input />
        </Form.Item>
      )}

      {visibleFields.seoKeywords && (
        <Form.Item label="Tag <meta> - keywords" name="seoKeywords">
          <Input />
        </Form.Item>
      )}

      {visibleFields.hotjarId && (
        <Form.Item label="Tag hotjarId" name="hotjarId">
          <Input />
        </Form.Item>
      )}

      {/* Zawsze wysyła 1 lub 0 */}
      <Form.Item name="isRecommended" valuePropName="checked">
        <Checkbox>Polecany</Checkbox>
      </Form.Item>

      <Form.Item name="isNew" valuePropName="checked">
        <Checkbox>Nowość</Checkbox>
      </Form.Item>

      {/* ------------------------------------------------------------------------------------------------------------------------------ */}

      <Form.Item label="Dodaj nowy rozmiar">
        <Input
          placeholder="Wpisz value (np. 100)"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleAddOption}
          style={{ width: "60%", marginRight: "10px" }}
        />
        <Select
          value={unitValue}
          onChange={(value) => setUnitValue(value)}
          style={{ width: "30%" }}
        >
          <Option value="cm">cm</Option>
          <Option value="mm">mm</Option>
          <Option value="m">m</Option>
        </Select>
      </Form.Item>

      <Button
        type="primary"
        onClick={handleAddOption}
        style={{ marginBottom: "10px" }}
      >
        Dodaj
      </Button>

      <Form.Item label="Rozmiar" name="sizes">
        <Checkbox.Group
          onChange={(checkedValues) =>
            handleSizeChange(checkedValues as string[])
          }
        >
          {sizesTab.map((size) => (
            <Checkbox key={size.value} value={size.value}>
              {size.value}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      {/* -------------------------------------------------------------------------------------------------------------------------------------------- */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Zatwierdź
        </Button>
      </Form.Item>
    </Form>
  );
}
