import { useEffect, useState } from "react";
import ItemCard from "../../../components/UI/ItemCard/ItemCard";
import "./CatalogCollections.scss";
import { useProduct } from "../../../hooks/useProduct";
import { IProduct, IProductFilteredRequest } from "../../../types/IProduct";
import { ICollection } from "../../../types/ICollection";

type CatalogProps = {
  title: string;
  photo: boolean;
  number?: number;
  collection?: ICollection;
  filter: IProductFilteredRequest;
};

export default function Collections({
  title,
  photo,
  number,
  collection,
  filter,
}: CatalogProps) {
  const [visibleCount, setVisibleCount] = useState(8);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("CATALOG FILTER: ", filter);
        const tempFilter = {
          ...filter,
        };
        if (collection) {
          tempFilter.collectionId = [collection.id];
        }

        const response = await product.getAll.execute(tempFilter);
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, [filter]);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  return (
    <>
      {products.length > 0 && (
        <div className="CollectionsPage2">
          <div className="CatalogHeading2">
            <h1>{title}</h1>
          </div>
          <div className="grid-container2">
            {products.slice(0, visibleCount).map((product, index) => {
              if (photo && index === number) {
                return (
                  <>
                    <div key={product.id} className="large-image2">
                      <img
                        alt="Modelka"
                        // src="/images/catalog.png"
                        src={`data:image/png;base64,${collection?.picture.directory}`}
                        className="full-image2"
                      />
                    </div>
                    <div key={index} className="grid-item2">
                      <ItemCard
                        product={product}
                        key={index}
                        content={"Kup teraz"}
                        showButton={false}
                        variant="catalog"
                      />
                    </div>
                  </>
                );
              }
              return (
                <div key={index} className="grid-item2">
                  <ItemCard
                    product={product}
                    key={index}
                    content={"Kup teraz"}
                    showButton={false}
                    variant="catalog"
                  />
                </div>
              );
            })}
          </div>
          {visibleCount < products.length && (
            <div className="show-more-container2">
              <p style={{ color: "white" }}>
                {visibleCount} z {products.length} produktów
              </p>
              <button onClick={handleShowMore} className="show-more-button2">
                Pokaż więcej
              </button>
            </div>
          )}
          <hr></hr>
        </div>
      )}
    </>
  );
}
