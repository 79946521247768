import { ISuperCategory } from "./ISuperCategory";
import { ICategory } from "./ICategory";
import { ICollection } from "./ICollection";
import { IType } from "./IType";
import { IAttribute } from "./IAttribute";

export enum ISortDirectionEnum {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export interface IProduct {
  id: number;
  superCategory?: ISuperCategory;
  category?: ICategory;
  collection?: ICollection;
  type?: IType;
  attribute: IAttribute;
}

export interface IProductFilteredRequest {
  currency: string;
  categoryId?: number[];
  collectionId?: number[];
  superCategoryId?: number[];
  typeId?: number[];
  minPrice?: number;
  maxPrice?: number;
  sortBy?: string;
  sortDirection?: string;
  colorId?: number[];
}

export const exampleProduct: IProduct = {
  id: 4,
  superCategory: {
    id: 1,
    name: "Brak",
  },
  category: {
    id: 1,
    name: "Brak",
    description: "brak",
    slug: "test",
    picture: {
      type: "1",
    },
  },
  collection: {
    id: 1,
    name: "Brak",
    picture: {
      type: "1",
    },
  },
  type: {
    id: 1,
    name: "Brak",
  },
  attribute: {
    id: 6,
    name: "Podgląd produktu",
    description:
      "A high-end luxury gold watch with precision mechanics and premium materials.",
    price: 12500.0,
    metal: "Gold",
    goldTest: 750,
    stone: "Diamond",
    stoneWeight: 0.5,
    clasp: "Fold-over clasp",
    referenceNumber: "LW-2025-GLD",
    diameter: 42.0,
    mechanism: "Automatic",
    dialColor: "Black",
    productCondition: "New",
    included: "Original box, warranty card, manual",
    productionDate: "2025-01-01",
    clarity: "VS1",
    cutType: "Brilliant",
    isRecommended: true,
    slug: "podglad_produktu",
    pictures: [],
    colors: [
      {
        id: 1,
        name: "Kolor 1",
      },
      {
        id: 2,
        name: "Kolor 2",
      },
      {
        id: 3,
        name: "Kolor 3",
      },
    ],
    sizes: [
      {
        id: 1,
        value: "1",
        unit: "cm",
      },
      {
        id: 2,
        value: "2",
        unit: "cm",
      },
      {
        id: 3,
        value: "3",
        unit: "cm",
      },
    ],
  },
};
