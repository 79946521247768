import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IProduct } from "../types/IProduct";
import { IStatus } from "../types/IStatus";
import { useStorage } from "./useStorage";
import { IOrder, IOrderResponse } from "../types/IOrder";

export interface IProductResponse {
  product: IProduct;
  status: IStatus;
}

export const useOrder = () => {
  const { api } = useApi();
  const { auth } = useAuth();
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  const order = {
    create: useAsync(async (order: IOrder): Promise<IOrderResponse> => {
      auth.validate();
      const response = await api.post<IOrderResponse>("/public/order", order);
      return response;
    }),
  };

  return { order };
};
