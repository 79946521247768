import { useState, useEffect } from "react";
import "./NavDrawer.scss";
import { NavDrawerTabs } from "./NavDrawerTabs/NavDrawerTabs";
import { IType } from "../../../../types/IType";
import { useType } from "../../../../hooks/useType";
import { useCollection } from "../../../../hooks/useCollection";
import { ICollection } from "../../../../types/ICollection";
import { useCategory } from "../../../../hooks/useCategory";
import { ICategory } from "../../../../types/ICategory";

interface DrawerProps {
  open: boolean;
  data: string;
}

interface Option {
  key: string;
  label: string;
  path: string;
  id: number;
}

export default function NavDrawer(props: DrawerProps) {
  const { open, data } = props;
  const [options, setOptions] = useState<Option[]>([]);
  const { category } = useCategory();
  const [typesData, setTypesData] = useState<ICategory[]>([]);
  const { collection } = useCollection();
  const [collectionsData, setCollectionsData] = useState<ICollection[]>([]);

  const [filterOption, setFilterOption] = useState<string>("");
  const products: Option[] = typesData.map((category) => ({
    key: category.id.toString(),
    id: category.id,
    label: category.name,
    path: `/kategorie/${category.slug.toLowerCase()}`,
  }));
  const collectionsTab: Option[] = collectionsData.map((collection) => ({
    key: collection.id.toString(),
    id: collection.id,
    label: collection.name,

    path: `/kolekcje`,
  }));

  const {
    execute: getAllCollections,
    loading: loadingCollections,
    // error: errorType,
    value: collections,
  } = collection.getAll;

  const {
    execute: getAllCategories,
    loading: loadingType,
    // error: errorType,
    value: categories,
  } = category.getAll;

  useEffect(() => {
    getAllCategories();
    getAllCollections();
  }, []);

  useEffect(() => {
    if (categories) {
      const extractedTypes = categories
        .filter(
          (item: any) =>
            item.category?.name?.toLowerCase() !== "brak" &&
            item.category?.name?.toLowerCase() !== "bestsellers" &&
            item.category?.name?.toLowerCase() !== "dla niego"
        )
        .map((item: any) => item.category);
      setTypesData(extractedTypes);
    }
  }, [categories]);

  useEffect(() => {
    if (collections) {
      const extractedCollections = collections
        .filter((item: any) => item.collection?.name?.toLowerCase() !== "brak")
        .map((item: any) => item.collection);
      setCollectionsData(extractedCollections);
    }
  }, [collections]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (data === "products") {
      setOptions(products);
      setFilterOption("type");
    } else {
      setOptions(collectionsTab);
      setFilterOption("collection");
    }
  }, [data, open]);

  return (
    <div className={`dropdown-content ${open ? "show-content" : ""}`}>
      <NavDrawerTabs filterOption={filterOption} options={options} />
    </div>
  );
}
