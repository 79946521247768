import React, { useState, useEffect, useRef, useContext } from "react";
import "./RecentlySeen.scss";
import Text from "../../../UI/Text/Text";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../../../UI/Carousel/CustomArrows/CustomArrows";
import { useProduct } from "../../../../hooks/useProduct";
import CartContext from "../../../../context/CartContext";
import { IProduct } from "../../../../types/IProduct";
import ItemCard from "../../../UI/ItemCard/ItemCard";
import RecentlySeenContext from "../../../../context/RecentlySeenContext";
import { IPicture } from "../../../../types/IPicture";

const carousel = [
  { imageSrc: "/images/Recently1.jpeg", text: "BRANSOLETKA INVISIBLE™" },
  { imageSrc: "/images/Recently2.jpeg", text: "Rolex DATEJUST 36" },
  { imageSrc: "/images/Recently1.jpeg", text: "LUKSUSOWA KOLIA Z BRYLANTAMI" },
];

const images = [
  { imageSrc: "/images/Recently1.jpeg", text: "LUKSUSOWA KOLIA Z BRYLANTAMI" },
  {
    imageSrc: "/images/Recently2.jpeg",
    text: "PIERŚCIONEK Tiffany One Setting",
  },
  { imageSrc: "/images/Recently1.jpeg", text: "ZEGAREK ROLEX DATEJUST 36" },
];

const RecentlyImages = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(500);
  const gap = 15;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [itemsToShow, setItemsToShow] = useState(0);

  const recentlySeenCtx = useContext(RecentlySeenContext);
  const recentlySeen = recentlySeenCtx.items;
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchCartProducts = async () => {
      const fetchedProducts: IProduct[] = [];
      const fetchedPictures: IPicture[] = [];

      for (const item of recentlySeen) {
        try {
          const response = await product.get.execute(item.id);
          if (response) {
            fetchedProducts.push({
              ...response.product,
            });

            fetchedPictures.push({
              ...response.product.attribute.pictures[0],
            });
          }
        } catch (error) {
          console.error(
            `Błąd podczas pobierania produktu o ID ${item.id}:`,
            error
          );
        }
      }

      setProducts(fetchedProducts);
      setProducts(fetchedProducts);
    };

    fetchCartProducts();
  }, []);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        const newItemsToShow = Math.floor(carouselWidth / (itemWidth + gap));
        setItemsToShow(newItemsToShow);
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);

    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, [itemWidth, gap]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setItemWidth(390);
      } else {
        setItemWidth(500);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, carousel.length - itemsToShow - 1)
    );
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < carousel.length - itemsToShow - 1;

  return products.length === 3 ? (
    <div className="recently-content">
      <div className="text-mobile">
        <Text
          title={"OSTATNIO OGLĄDANE"}
          text={
            "Podczas ostatiej wizyty na naszej stronie zainteresowało Cię kilka produktów. Może chcesz do nich wrócić?"
          }
          showButton={false}
          align={"text-center"}
          content={""}
          path={""}
        />
        <AdjustableButton text={"Zobacz Więcej"} href={"#"} />
      </div>
      <div className="recently-carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * (itemWidth + gap)}px)`,
          }}
          ref={itemsRef}
        >
          {products.map((product, index) => (
            <div key={index} className="carousel-item">
              <img
                src={`data:image/png;base64,${product.attribute.pictures[0].directory}`}
                alt={""}
              />
              <p>{product.attribute.name}</p>
            </div>
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
      </div>

      <div className="recently-text-images">
        <div className="recently-text">
          <Text
            title={"OSTATNIO OGLĄDANE"}
            text={
              "Podczas ostatiej wizyty na naszej stronie zainteresowało Cię kilka produktów. Może chcesz do nich wrócić?"
            }
            showButton={false}
            align={"text-left"}
            content={""}
            path={""}
          />
          <AdjustableButton text={"Zobacz Więcej"} href={"#"} />
        </div>
        <div className="recently-images">
          {products?.slice(0, 4).map((product, index) => {
            return (
              <div key={index} className="grid-item">
                <ItemCard
                  product={product}
                  key={index}
                  content={"product.content"}
                  showButton={false}
                  variant="search"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default RecentlyImages;
