import { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { IMainFoto } from "../../../../types/IMainFoto";
import MainFotoModal from "./MainFotoModal";
import { useMainFoto } from "../../../../hooks/useMainFoto";

function MainFoto() {
  const { mainFoto } = useMainFoto();
  const [data, setData] = useState<IMainFoto[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMainFoto, setCurrentMainFoto] = useState<IMainFoto | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllMainFoto,
    loading: loadingMainFoto,
    // error: errorMainFoto,
    value: mainFotos,
  } = mainFoto.getAll;

  const {
    execute: createMainFoto,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = mainFoto.create;

  const {
    execute: updateMainFoto,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = mainFoto.update;

  const {
    execute: deleteMainFoto,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = mainFoto.delete;

  useEffect(() => {
    getAllMainFoto();
  }, []);

  useEffect(() => {
    if (mainFotos) {
      const extractedCategories = mainFotos.map((item: any) => item.mainFoto);
      setData(extractedCategories);
    }
  }, [mainFotos]);

  const handleModalSubmit = async (values: IMainFoto) => {
    if (currentMainFoto) {
      try {
        await updateMainFoto(currentMainFoto.id, values);
        message.success("Zdjęcie zaktualizowane");
        getAllMainFoto();
      } catch (err) {
        console.error("Błąd przy aktualizacji zdjęcia:", err);
        message.error("Błąd przy aktualizacji zdjęcia");
      }
    } else {
      try {
        await createMainFoto(values);
        message.success("Zdjęcie dodane");
        getAllMainFoto();
      } catch (err) {
        console.error("Błąd przy dodawaniu zdjęcia:", err);
        message.error("Błąd przy dodawaniu zdjęcia");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteMainFoto(id);
      message.success("Zdjęcie usunięte");
      getAllMainFoto();
    } catch (err) {
      console.error("Błąd przy usuwaniu zdjęcia:", err);
      message.error("Błąd przy usuwaniu zdjęcia");
    }
  };

  const columns: ColumnsType<IMainFoto> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: IMainFoto) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentMainFoto(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć to zdjęcie?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  // const filteredData = data?.filter((mainFoto) =>
  //   mainFoto.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Zdjęcia główne</h1>

      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj zdjęcia..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentMainFoto(null);
              setIsModalVisible(true);
            }}
          >
            Dodaj Zdjęcie
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingMainFoto}
        />

        <MainFotoModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentMainFoto}
          loading={currentMainFoto ? loadingUpdate : loadingCreate}
        />
      </div>
    </>
  );
}

export default MainFoto;
