import { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { ICollection } from "../../../../types/ICollection";
import { useCollection } from "../../../../hooks/useCollection";
import CollectionModal from "./CollectionModal";

function Collection() {
  const { collection } = useCollection();
  const [data, setData] = useState<ICollection[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCollection, setCurrentCollection] =
    useState<ICollection | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllCollection,
    loading: loadingCollection,
    // error: errorCollection,
    value: collections,
  } = collection.getAll;

  const {
    execute: createCollection,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = collection.create;

  const {
    execute: updateCollection,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = collection.update;

  const {
    execute: deleteCollection,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = collection.delete;

  useEffect(() => {
    getAllCollection();
  }, []);

  useEffect(() => {
    if (collections) {
      const extractedCategories = collections.map(
        (item: any) => item.collection
      );
      setData(extractedCategories);
    }
  }, [collections]);

  const handleModalSubmit = async (values: ICollection) => {
    // console.log(values);
    if (currentCollection) {
      try {
        await updateCollection(currentCollection.id, values);
        message.success("Kategoria zaktualizowana");
        getAllCollection();
      } catch (err) {
        console.error("Błąd przy aktualizacji kategorii:", err);
        message.error("Błąd przy aktualizacji kategorii");
      }
    } else {
      try {
        await createCollection(values);
        message.success("Kategoria dodana");
        getAllCollection();
      } catch (err) {
        console.error("Błąd przy dodawaniu kategorii:", err);
        message.error("Błąd przy dodawaniu kategorii");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteCollection(id);
      message.success("Kategoria usunięta");
      getAllCollection();
    } catch (err) {
      console.error("Błąd przy usuwaniu kategorii:", err);
      message.error("Błąd przy usuwaniu kategorii");
    }
  };

  const columns: ColumnsType<ICollection> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: ICollection) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentCollection(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć tę kategorię?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const filteredData = data?.filter((collection) =>
    collection.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Kolekcje</h1>

      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj produktu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentCollection(null);
              setIsModalVisible(true);
            }}
          >
            Dodaj Kolekcje
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingCollection}
        />

        <CollectionModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentCollection}
          loading={currentCollection ? loadingUpdate : loadingCreate}
        />
      </div>
    </>
  );
}

export default Collection;
