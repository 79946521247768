import "./ProductItem.scss";
import { IProduct } from "../../../../../types/IProduct";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { IColor } from "../../../../../types/IColor";
import { ISize } from "../../../../../types/ISize";

export default function ProductItem(props: {
  products: (IProduct & {
    quantity: number;
    color: IColor;
    size?: ISize | undefined;
  })[];
  handleAddToCart: (
    product: IProduct & {
      quantity: number;
      color: IColor;
      size?: ISize | undefined;
    }
  ) => void;
  handleDeleteItemFromCart: (
    productId: number,
    color: IColor,
    size?: ISize | undefined
  ) => void;
  handleDeleteQuantityFromCart: (
    product: IProduct & {
      quantity: number;
      color: IColor;
      size?: ISize | undefined;
    }
  ) => void;
}) {
  const {
    products,
    handleAddToCart,
    handleDeleteItemFromCart,
    handleDeleteQuantityFromCart,
  } = props;

  return (
    <div className="ProductItemContent">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>PRODUKT</th>
            <th>ROZMIAR</th>
            <th>KOLOR</th>
            <th>ILOŚĆ</th>
            <th>CENA</th>
            <th>WARTOŚĆ</th>
            <th>AKCJE</th>
          </tr>
        </thead>
        <tbody>
          {products.map((item, index) => (
            <tr key={index}>
              <td>
                <img
                  src={`data:image/png;base64,${item.attribute?.pictures[0].directory}`}
                  alt="Ładowanie..."
                  className="ProductImage"
                  style={{ height: "150px" }}
                />
              </td>
              <td>
                <p>{item.attribute?.name}</p>
              </td>
              <td>
                {item.size
                  ? `${item.size.value} ${item.size.unit}`
                  : "ONE SIZE"}
              </td>
              <td>{item.color.name}</td>
              <td>
                <MinusOutlined
                  onClick={() => handleDeleteQuantityFromCart(item)}
                  size={20}
                />
                {"  "}
                {item.quantity}
                {"  "}
                <PlusOutlined onClick={() => handleAddToCart(item)} />
              </td>
              <td>{item.attribute?.price}</td>
              <td>{(item.attribute?.price || 0) * item.quantity}</td>
              <td>
                <p
                  onClick={() =>
                    handleDeleteItemFromCart(item.id, item.color, item.size)
                  }
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Usuń
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
