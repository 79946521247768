import { Dropdown, MenuProps, Checkbox, Typography, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSuperCategory } from "../../../hooks/useSuperCategory";
import { useCategory } from "../../../hooks/useCategory";
import { useCollection } from "../../../hooks/useCollection";
import { useType } from "../../../hooks/useType";
import { useColor } from "../../../hooks/useColor";

const { Text } = Typography;

interface Identifiable {
  id: React.Key;
  name: string;
}

interface ProductFormSelectProps<T extends Identifiable> {
  placeholder: string;
  value: T[];
  onChange: (value: T[]) => void;
  dataType: "supercategory" | "category" | "collection" | "type" | "color";
}

function CheckboxDropdown<T extends Identifiable>({
  placeholder,
  value,
  onChange,
  dataType,
}: ProductFormSelectProps<T>) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const { supercategory } = useSuperCategory();
  const { category } = useCategory();
  const { collection } = useCollection();
  const { type } = useType();
  const { color } = useColor();

  useEffect(() => {
    setLoading(true);
    let fetchFunction: () => Promise<T[]>;

    switch (dataType) {
      case "supercategory":
        fetchFunction = async () => {
          const response = await supercategory.getAll.execute();
          return response
            .filter(
              (item: any) => item.superCategory?.name?.toLowerCase() !== "brak"
            )
            .map((item: any) => item.superCategory);
        };
        break;
      case "category":
        fetchFunction = async () => {
          const response = await category.getAll.execute();
          return response
            .filter(
              (item: any) => item.category?.name?.toLowerCase() !== "brak"
            )
            .map((item: any) => item.category);
        };
        break;
      case "collection":
        fetchFunction = async () => {
          const response = await collection.getAll.execute();
          return response
            .filter(
              (item: any) => item.collection?.name?.toLowerCase() !== "brak"
            )
            .map((item: any) => item.collection);
        };
        break;
      case "type":
        fetchFunction = async () => {
          const response = await type.getAll.execute();
          return response
            .filter((item: any) => item.type?.name?.toLowerCase() !== "brak")
            .map((item: any) => item.type);
        };
        break;
      case "color":
        fetchFunction = async () => {
          const response = await color.getAll.execute();
          return response
            .filter((item: any) => item.color?.name?.toLowerCase() !== "brak")
            .map((item: any) => item.color);
        };
        break;
      default:
        setError("Nieznany typ danych.");
        setLoading(false);
        return;
    }

    fetchFunction()
      .then((result) => {
        setData(result);
        setLoading(false);
      })
      .catch(() => {
        setError("Wystąpił błąd podczas ładowania danych.");
        setLoading(false);
      });
  }, [dataType]);

  const handleCheckboxChange = (item: T) => {
    if (value.some((v) => v.id === item.id)) {
      onChange(value.filter((v) => v.id !== item.id));
    } else {
      onChange([...value, item]);
    }
  };

  const menuItems: MenuProps["items"] = data.map((item) => ({
    key: item.id.toString(),
    label: (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={value.some((v) => v.id === item.id)}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            handleCheckboxChange(item);
          }}
        >
          {item.name}
        </Checkbox>
      </div>
    ),
  }));

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["click"]}
      overlayClassName="dropdown"
      placement="bottomLeft"
      open={openDropdown}
      onOpenChange={(open) => setOpenDropdown(open)}
    >
      <Text style={{ cursor: "pointer" }}>
        {loading ? (
          <Spin size="small" />
        ) : error ? (
          <span style={{ color: "red" }}>{error}</span>
        ) : (
          placeholder + " "
        )}
        <DownOutlined
          className={openDropdown ? "sortDownOpened" : "sortDown"}
        />
      </Text>
    </Dropdown>
  );
}

export default CheckboxDropdown;
