import { useEffect, useState } from "react";
import Page from "../../components/UI/Page/Page";
import { useCollection } from "../../hooks/useCollection";
import { ICollection } from "../../types/ICollection";
import Catalog from "./Catalog/Catalog";
import "./Collections.scss";
import Sort from "./Sort/Sort";
import { IProductFilteredRequest } from "../../types/IProduct";
import { useStorage } from "../../hooks/useStorage";

export default function Collections() {
  const { collection } = useCollection();
  const [collections, setCollections] = useState<ICollection[]>([]);
  const { storage } = useStorage();
  const currency = storage.local.get("currency");
  const [filter, setFilter] = useState<IProductFilteredRequest>({
    currency: currency ?? "PLN",
  });

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await collection.getAll.execute();
        if (response) {
          const extractedCollections = response.map((item) => item.collection);
          setCollections(extractedCollections);
          console.log(extractedCollections);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchCollections();
  }, []);

  return (
    <Page>
      <div className="CollectionsHeading">
        <h1>KOLEKCJE</h1>
      </div>
      <Sort setFilter2={setFilter} />
      {collections.map((collection, index) => {
        const isCollectionValid =
          collection.name.toLowerCase() !== "brak" &&
          (filter.collectionId && filter.collectionId.length > 0
            ? filter.collectionId.includes(collection.id)
            : true);

        return isCollectionValid ? (
          <Catalog
            key={index}
            photo={true}
            number={index % 2 === 0 ? 0 : 2}
            title={collection.name}
            filter={filter}
            collection={collection}
          />
        ) : null;
      })}
    </Page>
  );
}
