import Page from "../../components/UI/Page/Page";
import PictureHeader from "../../components/UI/PictureHeader/PictureHeader";
import ProductRow from "../../components/Pages/NewPage/ProductRow/ProductRow";
import { useProduct } from "../../hooks/useProduct";
import { useEffect, useState } from "react";
import { IProduct } from "../../types/IProduct";

export default function New() {
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute({ isNew: 1 });
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
          console.log(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <Page>
      <PictureHeader
        heading="NOWOŚCI"
        description="Poznaj najnowsze produkty dodane do naszej oferty."
        imgSrc="./images/FooterImage.jpeg"
      />
      {products?.map((product, index) => (
        <ProductRow
          key={product.id}
          product={product}
          reverse={index % 2 === 1}
        />
      ))}
    </Page>
  );
}
