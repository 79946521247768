import { useContext, useEffect, useState } from "react";
import ItemCard from "../../../components/UI/ItemCard/ItemCard";
import "./Catalog.scss";
import { useProduct } from "../../../hooks/useProduct";
import { IProduct, IProductFilteredRequest } from "../../../types/IProduct";
import { ICollection } from "../../../types/ICollection";
import SortContext from "../../../context/SortContext";

type CatalogProps = {
  title: string;
  photo: boolean;
  number?: number;
  collection?: ICollection;
};

export default function Collections({
  title,
  photo,
  number,
  collection,
}: CatalogProps) {
  const [visibleCount, setVisibleCount] = useState(8);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);
  const sortCtx = useContext(SortContext);
  const filter = sortCtx.filter;

  useEffect(() => {
    if (!sortCtx.isSorting) return;

    const fetchProducts = async () => {
      try {
        console.log("CATALOG FILTER: ", filter);
        // const tempFilter = {
        //   ...filter,
        // };
        // if (collection) {
        //   tempFilter.collectionId = [collection.id];
        // }

        const response = await product.getAll.execute(filter);
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      } finally {
        sortCtx.triggerSorting(false);
      }
    };
    fetchProducts();
  }, [sortCtx.isSorting]);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  return (
    <>
      {products.length > 0 && (
        <div className="CollectionsPage">
          <div className="CatalogHeading">
            <h1>{title}</h1>
          </div>
          <div className="grid-container">
            {products.slice(0, visibleCount).map((product, index) => {
              if (photo && index === number) {
                return (
                  <>
                    <div key={product.id} className="large-image">
                      <img
                        alt="Modelka"
                        src="/images/catalog.png"
                        className="full-image"
                      />
                    </div>
                    <div key={index} className="grid-item">
                      <ItemCard
                        product={product}
                        key={index}
                        content={"Kup teraz"}
                        showButton={false}
                        variant="catalog"
                      />
                    </div>
                  </>
                );
              }
              return (
                <div key={index} className="grid-item">
                  <ItemCard
                    product={product}
                    key={index}
                    content={"Kup teraz"}
                    showButton={false}
                    variant="catalog"
                  />
                </div>
              );
            })}
          </div>
          {visibleCount < products.length && (
            <div className="show-more-container">
              <p style={{ color: "white" }}>
                {visibleCount} z {products.length} produktów
              </p>
              <button onClick={handleShowMore} className="show-more-button">
                Pokaż więcej
              </button>
            </div>
          )}
          <hr></hr>
        </div>
      )}
    </>
  );
}
